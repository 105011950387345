import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import { CustomerDto, UpdateCustomerRequest } from '@/api/contracts/models'
import QueryKeys from '@/vue-query/QueryKeys'
import { useCustomerApi } from '@/composables/api/useCustomerApi'

export function useCustomerUpdate(queryClient: QueryClient) {
   const client = useCustomerApi()

   const apiFn = (data: UpdateCustomerRequest) => {
      return client.update(data.customerId!, data)
   }

   const optimisticFn = (old: CustomerDto[], data: UpdateCustomerRequest) => {
      const values = old ? [...old] : []

      const customer = values.find((m) => m.id == data.customerId)

      if (customer) {
         const endpointIdx = values.indexOf(customer)

         values[endpointIdx] = {
            ...customer,
            name: data.displayName,
            displayName: data.displayName
         }
      }

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.Customers],
      queryClient,
      apiFn,
      optimisticFn
   )
}

import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import { ProjectDto, CreateProjectRequest } from '@/api/contracts/models'
// import { useToast } from '@/helpers/Notifications'
import QueryKeys from '@/vue-query/QueryKeys'
import { useProjectApi } from '../api/useProjectApi'

export function useProjectCreate(queryClient: QueryClient) {
   const client = useProjectApi()
   const apiFn = (data: CreateProjectRequest) => client.create(data)

   const optimisticFn = (old: ProjectDto[], data: CreateProjectRequest) => {
      const values = old ? [...old] : []

      values.push({
         ...data
      })

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.Projects],
      queryClient,
      apiFn,
      optimisticFn
   )
}

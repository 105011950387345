import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import { CreateCustomerRequest, CustomerDto } from '@/api/contracts/models'
import QueryKeys from '@/vue-query/QueryKeys'
import { useCustomerApi } from '../api/useCustomerApi'

export function useCustomerCreate(queryClient: QueryClient) {
   const client = useCustomerApi()

   const apiFn = (data: CreateCustomerRequest) =>
      client.create(data)

   const optimisticFn = (old: CustomerDto[], data: CreateCustomerRequest) => {
      const values = old ? [...old] : []

      values.push({
         id: data.customerId,
         name: data.name,
         displayName: data.displayName
      })

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.Customers],
      queryClient,
      apiFn,
      optimisticFn
   )
}
